<template>
  <el-main>
    <el-tabs v-model="type" @tab-click="getAgreement">
      <el-tab-pane label="安装类接单" name="1"></el-tab-pane>
      <el-tab-pane label="设计类接单" name="2"></el-tab-pane>
      <el-tab-pane label="广告类接单" name="3"></el-tab-pane>
      <el-tab-pane label="用户服务" name="4"></el-tab-pane>
      <el-tab-pane label="注册用户" name="5"></el-tab-pane>
      <el-tab-pane label="注册隐私" name="6"></el-tab-pane>
    </el-tabs>
    <RichText ref="richText" :richTxt="content" @soninfo="val => (content = val)"></RichText>
    <Preservation @preservation="save"></Preservation>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    RichText,
    Preservation,
  },
  data() {
    return {
      type: '1',
      content: '',
    };
  },
  created() {
    this.getAgreement();
  },
  methods: {
    getAgreement() {
      this.$axios
        .post(this.$api.samecity.serviceAgreementInfo, {
          type: this.type,
        })
        .then(res => {
          if (res.code == 0) {
            this.$refs.richText.is_editor = true;
            this.content = res.result.content;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    save() {
      this.$axios
        .post(this.$api.samecity.serviceAgreementEdit, {
          type: this.type,
          content: this.content,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  height: 100%;
}
</style>